<mat-card>
	@if (!isPlatformServer){
		<div>
			<div>
				<p>Welcome, enter your credentials</p>
			</div>
			<div class="form-container">
				<form [formGroup]="form" (ngSubmit)="login()">
					<mat-form-field floatLabel="auto">
						<input matInput type="email" placeholder="Email" name="email" formControlName="email" visionarAdminInputEmptyToNull required>
					</mat-form-field>
					<mat-form-field floatLabel="auto">
						<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" visionarAdminInputEmptyToNull required>
					</mat-form-field>
					<div>
						<visionar-admin-form-message [serverErrorInput]="serverError" [serverResponseInput]="serverResponse"></visionar-admin-form-message>
					</div>
					<div>
						<button type="submit" mat-button class="app-primary">Log in</button>
					</div>
				</form>
			</div>
		</div>
	}
</mat-card>
