import {Component,Input} from '@angular/core';

@Component({
	selector:'visionar-admin-form-message',
	standalone:true,
	imports:[],
	templateUrl:'./form-message.component.html',
	styleUrl:'./form-message.component.scss'
})
export class FormMessageComponent{
	@Input() serverErrorInput?:any;
	@Input() serverResponseInput?:any;
}
