export const environment={
	production:false,
	apiServer:{
		url:'https://api-test.thevision.ar'
	},
	webServer:{
		url:'https://test.thevision.ar'
	},
	adminServer:{
		url:'https://admin-test.thevision.ar'
	}
};
