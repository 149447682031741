import {afterNextRender,ApplicationRef,Inject,Injectable} from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';
import {BehaviorSubject,firstValueFrom,map,Observable} from 'rxjs';
import {ApolloQueryResult} from '@apollo/client/core';
import {LocalStorage,LocalStorageService} from 'ngx-webstorage';
import {DOCUMENT} from '@angular/common';
import {GetInitialDataQuery,GetInitialDataGQL} from './common/graphql/get-initial-data.generated';
import {UserResponseDto} from './authentication/dto/user-response.dto';

@Injectable({
	providedIn:'root'
})
export class AppService{
	document:Document;
	private readonly title:Title;
	private readonly meta:Meta;
	private readonly applicationRef:ApplicationRef;
	private readonly getInitialDataGQL:GetInitialDataGQL;
	private userSource:BehaviorSubject<UserResponseDto|null|undefined>;
	private themeSource:BehaviorSubject<string|undefined>;
	@LocalStorage('theme')
	private themeLocalStorage?:string;
	private localStorageService:LocalStorageService;
	
	user:Observable<UserResponseDto|null|undefined>;
	theme:Observable<string|undefined>;
	
	
	constructor(
		@Inject(DOCUMENT) document:Document,
		title:Title,
		meta:Meta,
		applicationRef:ApplicationRef,
		getInitialDataGQL:GetInitialDataGQL,
		localStorageService:LocalStorageService
	){
		this.document=document;
		this.title=title;
		this.meta=meta;
		this.applicationRef=applicationRef;
		this.getInitialDataGQL=getInitialDataGQL;
		this.userSource=new BehaviorSubject<UserResponseDto|null|undefined>(undefined);
		this.themeSource=new BehaviorSubject<string|undefined>(this.themeLocalStorage);
		this.localStorageService=localStorageService;
		
		this.user=this.userSource.asObservable();
		this.theme=this.themeSource.asObservable()
		.pipe(
			map((value:string|undefined):string=>{
				this.document.body.classList.remove('app-dark-theme');
				this.document.body.classList.remove('app-light-theme');
				if(!value){
					this.localStorageService.clear('theme');
					value='app-light-theme';
				}
				this.document.body.classList.add(value);
				return value;
			})
		);
		
		
		afterNextRender(async():Promise<void>=>{
			if(this.document.defaultView){
				const darkColorScheme:MediaQueryList=this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');
				darkColorScheme.addEventListener('change',():void=>{
					if(!this.themeLocalStorage){
						this.themeSource.next(darkColorScheme.matches ? 'app-dark-theme' : 'app-light-theme');
						this.applicationRef.tick();
					}
				});
				setTimeout(():void=>{
					darkColorScheme.dispatchEvent(new MediaQueryListEvent('change'));
				},1000);
			}
		});
		
		firstValueFrom(this.getInitialData()).then().catch((error)=>console.error(error));
		
	}
	
	updateSeo(title:string,description?:string,keywords?:string,photo?:string):void{
		this.title.setTitle(title);
		this.meta.updateTag({content:title},'property="og:title"');
		this.meta.updateTag({content:this.document.location.href},'property="og:url"');
		if(description){
			this.meta.updateTag({content:description},'name="description"');
			this.meta.updateTag({content:description},'property="og:description"');
		}
		if(keywords){
			this.meta.updateTag({content:keywords},'name="keywords"');
		}
		if(photo){
			this.meta.updateTag({content:photo},'property="og:image"');
		}
	}
	
	setUser(data?:UserResponseDto|null):void{
		console.log('setUser',data);
		this.userSource.next(data);
	}
	
	toggleDarkTheme():void{
		if(!this.themeSource.value){
			this.themeSource.next('app-dark-theme');
			this.themeLocalStorage='app-dark-theme';
		}else if(this.themeSource.value==='app-light-theme'){
			this.themeSource.next('app-dark-theme');
			this.themeLocalStorage='app-dark-theme';
		}else if(this.themeSource.value==='app-dark-theme'){
			this.themeSource.next('app-light-theme');
			this.themeLocalStorage='app-light-theme';
		}
	}
	
	getInitialData():Observable<GetInitialDataQuery>{
		return this.getInitialDataGQL
		.fetch({},{})
		.pipe(
			map((response:ApolloQueryResult<GetInitialDataQuery>):GetInitialDataQuery=>{
				return response.data;
			})
		);
	}
	
}
